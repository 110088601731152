.loader-container {
  position: fixed;
  inset: 0; /* Same as top: 0; right: 0; bottom: 0; left: 0 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  height: 48px; /* h-12 */
  width: 48px; /* w-12 */
  border-radius: 50%; /* rounded-full */
  border-top: 4px solid transparent; /* border-t-transparent */
  border-bottom: 4px solid #3b82f6; /* border-b-4 blue */
  border-right: 4px solid #3b82f6; /* border-r-4 blue */
  border-left: 4px solid #3b82f6; /* border-l-4 blue */
  animation: spin 1s linear infinite; /* animate-spin */
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  margin-left:53%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
